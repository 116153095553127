import api from './api'

const url = 'notice-board'

const getNoticeBoardActive = () => api.get(`${url}/find-actives-notice-board/active/`)

const readConfirmationNoticieBoard = (noticeBoardDTO) => api.post(`${url}/create-read-confirmation`, noticeBoardDTO)

const getReadConfirmationByUser = (userAd) => api.get(`${url}/get-notice-confirmation/${userAd}`)

export { getNoticeBoardActive, readConfirmationNoticieBoard, getReadConfirmationByUser }

<template>
  <form>
    <v-container fluid class="pa-0">
      <v-row
        v-if="noticeBoardListFiltered.length > 0"
        align="center"
        justify="center"
        class="ma-0 pa-0"
      >
        <v-col
          v-for="notice in noticeBoardListFiltered"
          :key="notice.id"
          cols="12"
          class="pa-0"
        >
          <v-card color="indigo lighten-5" elevation="20" class="mb-4">
            <v-row justify="center" class="ma-0 pa-0">
              <v-col cols="12" md="8" sm="8" class="baseImg-fit pa-0">
                <v-img class="image-fit" :src="notice.link" />
              </v-col>
            </v-row>
            <v-row justify="center" class="mt-5 pa-0">
              <v-col class="d-flex flex-wrap justify-center justify-md-space-between pa-0" cols="12" md="8" sm="8">
                <div class="d-flex flex-column text-center">
                  <h2 class="text-h4 text-md-h4 text-sm-h5">{{ notice.title }}</h2>
                </div>
                <div class="text-center  ml-4 mt-2">
                  <p class="text-body-2 text-md-body-1">{{ notice.initialDate | dateFormatted }}</p>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-10 justify-center ma-0 pa-0">
              <v-col cols="12" md="8" class="pa-2 pa-md-0">
                <div class="text-body-2 text-md-body-1 text-justify" v-html="notice.text"></div>
              </v-col>
            </v-row>
            <attachment-card :attachment="notice.attc" />
            <v-row v-if="verifyNoticeConfirmationByUser(notice.id)" class="mt-10 justify-center ma-0 pa-0">
              <v-col class="mx-auto pa-3 text-center" cols="12" md="8">
                Aviso já lido
                <v-icon class="ml-1">mdi-check-decagram</v-icon>
              </v-col>
            </v-row>
            <v-row v-else class="mt-10 justify-center ma-0 pa-0">
              <v-col cols="12" md="8" class="pa-0">
                <v-checkbox
                  v-model="confirmRead"
                  label="Confirmar Leitura"
                  :value="notice.id"
                  hide-details
                  color="red"
                  @click="openDialogReadConfirmation"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else
        align="center"
        justify="center"
        class="px-12 pt-16"
      >
        <v-col cols="12" class="text-center">
          <h3 class="text--disabled">Não há nenhum aviso por enquanto.</h3>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">Confirmação de Leitura</v-card-title>
            <v-card-text>
              Ao confirmar a leitura do Mural de Avisos,
              <b>você não poderá desconfirmar</b> que leu e concordou com o Aviso!
              <br>
              Se deseja continuar, clique em <b>CONCORDO</b>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn color="secondary" text @click="readConfirmation">CONCORDO</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="update" persistent width="900">
          <v-card>
            <v-card-title class="text-h5">Atualização dos dados</v-card-title>
            <v-card-text>
              Para podermos enviar notificações importantes, precisamos atualizar seus dados.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="green-darken-1" variant="text" to="/contact">Atualizar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-container>
  </form>
</template>


<script>
import {
  getNoticeBoardActive,
  readConfirmationNoticieBoard,
  getReadConfirmationByUser
} from '../../services/notice-board.service'
import { mask } from 'vue-the-mask'
import Collaborator from '../../services/collaborator-service'
import dayjs from 'dayjs'

export default {
  directives: { mask },
  components: {
    'attachment-card': () => import('@/components/AttachmentCards')
  },
  data () {
    return {
      loggedUser: this.$store.getters['auth/userName'],
      tab: null,
      items: ['Avisos.'],
      noticeBoardList: [],
      noticeBoardListFiltered: [],
      noticesConfirmationList: [],
      panel: [0, 1],
      disabled: false,
      readonly: false,
      dialog: false,
      confirmRead: null,
      userAd: this.$store.getters['auth/userAd'],
      update: false,
      updatedAt: null,
    }
  },
  async created () {
    await this.loadData();
    this.verifyContact();
  },
  methods: {
    async loadData () {
      try {
        const allNotices = await getNoticeBoardActive()
        const today = new Date()

        this.noticeBoardListFiltered = allNotices.filter(notice => {
          const finalDate = new Date(notice.finalDate)
          return notice.active && finalDate >= today
        })

        this.noticesConfirmationList = await getReadConfirmationByUser(this.userAd)
      } catch (e) {
        this.$handleHttpError(e)
      }
    },
    openDialogReadConfirmation () {
      try {
        this.dialog = true
      } catch (e) {
        this.$handleHttpError(e)
      }
    },
    async verifyContact() {
      const contato = await Collaborator.getContact(this.userAd)
      const today = new Date()
      const currentYear = today.getFullYear()

      if (contato.email == null || contato.yearUpdated < currentYear) {
        contato.user = this.userAd
        contato.name = this.loggedUser
        this.update = true
      }
    },
    async readConfirmation () {
      try {
        this.dialog = false
        const noticeBoardDTO = {
          idWarning: this.confirmRead,
          userAd: this.userAd
        }
        const response = await readConfirmationNoticieBoard(noticeBoardDTO)
        if (response) {
          this.$toast.success('Confirmado com sucesso!')
          this.noticeBoardListFiltered = []
          this.noticesConfirmationList = []
          await this.loadData()
        }
      } catch (e) {
        this.$handleHttpError(e)
        this.$toast.error('Ocorreu um erro interno, por favor tente novamente.')
      }
    },
    verifyNoticeConfirmationByUser (noticeId) {
      return this.noticesConfirmationList.find(notice => notice.idWarning === noticeId)
    },
  }
}
</script>

<style>
@media (min-width: 1366px) {
.image-fit {
  width: 100%;
  height: auto;
  max-width: 600px;
  max-height: 600px; 
  object-fit: contain;
}

.container-fit {
  justify-content: center;
  align-items: center;
}

.baseImg-fit {
  display: flex;
  justify-content: center;
  align-items: center;
}
}
</style>
